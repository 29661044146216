<template>
  <div class="main">
    <div class="searchBox">
      <div class="checkName" @click="state.showPicker = true">
        {{ state.label }}
      </div>
      <van-icon
        name="replay"
        @click="getOutLetList"
        size="20px"
        color="#2C64F2"
      />
    </div>
    <div class="collapse_content">
      <van-collapse
        v-model="activeNames"
        v-if="outletlist.length > 0"
        accordion
        @change="getFactorlist"
      >
        <van-collapse-item
          v-for="items in outletlist"
          :key="items.id"
          :name="items.id"
          style="margin-bottom:10px;"
        >
          <template #title>
            <div>
              {{ items.name }}
              <van-tag class="activeVanTag" color="#ff0000">{{
                items.factor_num
              }}</van-tag>
            </div>
          </template>
          <div class="factorDetail">
            <van-list style="margin-bottom:10px" v-if="factorList.length > 0">
              <van-cell v-for="item in factorList" :key="item.id">
                <van-row justify="space-between">
                  <van-col span="20" align="left"
                    >{{ item.factor_id }}[{{ item.monitor_date }}]</van-col
                  >
                  <van-col span="4" align="right">{{
                    item.monitor_value
                  }}</van-col>
                </van-row>
              </van-cell>
            </van-list>
            <van-empty v-else :description="FactorDescription" />
            <van-row justify="end">
              <van-col span="24" align="right">
                <van-button
                  type="primary"
                  size="mini"
                  :to="
                    '/history/' +
                      type +
                      '/' +
                      title +
                      '/' +
                      state.queryId +
                      '/' +
                      items.tablename_factor +
                      '/' +
                      getFactorKey(factorList) +
                      '/' +
                      items.id +
                      '/' +
                      state.value
                  "
                  >历史</van-button
                >
              </van-col>
            </van-row>
          </div>
        </van-collapse-item>
      </van-collapse>
      <van-empty v-else :description="OutletDescription" />
    </div>
    <!-- 选择器 -->
    <van-popup v-model:show="state.showPicker" position="bottom">
      <van-picker
        :columns="columns"
        @confirm="onConfirm"
        @cancel="state.showPicker = false"
        :columns-field-names="customFieldName"
      />
    </van-popup>
  </div>
</template>
<script>
import { ref, reactive, onMounted, watch } from "vue";
import { useRoute } from "vue-router";
import Http from "../../../api/http";
export default {
  setup() {
    const route = useRoute();
    const activeNames = ref("0");
    const state = reactive({
      label: "类型",
      value: "",
      queryId: "0",
      showPicker: false
    });
    const columns = ref([]);
    const customFieldName = {
      text: "label",
      id: "value"
    };
    const onConfirm = obj => {
      state.label = obj.label;
      state.queryId = obj.history_query_id;
      state.value = obj.tablename_online_manual;
      state.showPicker = false;
    };
    const type = ref(route.params.type);
    const title = ref(route.params.title);
    const outletlist = ref([]);
    const factorList = ref([]);
    const FactorDescription = ref("加载中...");
    const OutletDescription = ref("加载中...");

    const getTypeList = () => {
      Http.get(
        {
          method: "list",
          table: "VIEW_MON_EPP_POINT_ONLINE_MANUAL_TABLE",
          tablename: type.value
        },
        false,
        true
      )
        .then(res => {
          var map = res.map;
          columns.value = map;
          if (map.length > 0) {
            state.label = map[0].label;
            state.value = map[0].tablename_online_manual;
            state.queryId = map[0].history_query_id;
          }
        })
        .catch(() => {});
    };
    const getOutLetList = () => {
      activeNames.value = 0;
      Http.get({
        method: "query",
        queryId: 29,
        tablename: type.value,
        tablename_online_manual: state.value
      })
        .then(res => {
          if (res.map.length > 0) {
            outletlist.value = res.map;
          } else {
            outletlist.value = [];
            OutletDescription.value = "暂无排口数据";
          }
        })
        .catch(() => {});
    };
    const getFactorlist = val => {
      factorList.value = [];
      Http.get(
        {
          method: "list",
          table: "VIEW_MON_EPP_POINT_ONLINE_MANUAL",
          tablename: type.value,
          tablename_online_manual: state.value,
          outlet_id: val
        },
        false,
        true
      )
        .then(res => {
          if (res.map.length > 0) {
            factorList.value = res.map;
          } else {
            factorList.value = [];
            FactorDescription.value = "暂无因子数据";
          }
        })
        .catch(() => {});
    };
    const getFactorKey = list => {
      var pl = [];
      list.forEach(element => {
        pl.push(element.tempfactor_id);
      });
      var plstr = pl.length > 0 ? pl.join(",") : 0;
      return plstr;
    };
    watch(
      () => state.value,
      (newval, oldval) => {
        if (newval != oldval) {
          getOutLetList();
        }
      }
    );
    onMounted(() => {
      getTypeList();
    });
    return {
      activeNames,
      state,
      columns,
      onConfirm,
      customFieldName,
      type,
      title,
      outletlist,
      factorList,
      FactorDescription,
      OutletDescription,
      getTypeList,
      getOutLetList,
      getFactorlist,
      getFactorKey
    };
  }
};
</script>
